<template>
  <section class="banner">
    <div class="banner-wrapper">
      <div class="container">
        <div class="row banner-items">

          <div class="col-xl-4 col-md-6 col-sm-12 banner-items__form">
            <div class="form-wrapper">
              <div class="form-header">Получить подарок<br> при ТО</div>
              <form @submit.prevent="sendForm">
<!--                <input class="input" v-model="form.name" placeholder="Ваше имя" name="name" required/>-->
                <phone-input v-model="form.phone"/>

                <label class="form-agreement">
                  <input type="checkbox" checked required/>
                  <div class="form-agreement__checkmark">
                    <i class="form-agreement__checkmark-icon"></i>
                  </div>
                  <div class="form-agreement__label">
                    Вы даете согласие на<br>
                    <a href="" @click.prevent="$root.$refs.policy.toggle(true)">обработку персональных данных</a>
                  </div>
                </label>
                <button class="button">Оставить заявку</button>
              </form>
            </div>
          </div>

          <div class="col-xl-5 col-lg-6 col-md-12 banner-items__first">
            <div class="banner-text">
              <div class="banner-title">Только в марте!<br>При прохождении планового ТО - щетки стеклоочистителей<br>В ПОДАРОК.
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-6 banner-items__lottery">
            <div class="banner-lottery">
                <h2 class="banner-lottery-header">Платите частями</h2>
                <img src="../assets/images/split-logo.png">
<!--              <img src="../assets/images/lottery.png" @click="$root.$refs.lottery.show()">-->
<!--              <button class="button" @click="$root.$refs.lottery.show()">Играть</button>-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="lottery-mobile">
      <div class="container">
        <div class="col-md-12">
          <div class="banner-lottery">
              <h2 class="banner-lottery-header">Платите частями</h2>
              <img src="../assets/images/split-logo.png">
<!--            <img src="../assets/images/lottery.png">-->
<!--            <button class="button" @click="$root.$refs.lottery.show()">Играть</button>-->
          </div>
        </div>
      </div>
      </div>
  </section>
</template>

<script>

export default {
  name: 'Banner',
  data() {
    return {
      form: {
        subject: 'Онлайн запись на сервис на баннере',
        title: 'Запись на сервис',
        order_id: 6,
        calltouch_id: 34101,
        name: 'name',
        phone: ''
      }
    }
  },

  methods: {
    async sendForm() {
      await this.$root.$refs.modal.submit(this.form);
      this.form.phone = '';
    }
  }
}
</script>
